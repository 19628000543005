import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination, useGlobalFilter, useSortBy } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

function DataTable({
  canSearch,
  showTotalEntries,
  table,
  pagination,
  entriesPerPage,
  isSorted,
  noEndBorder,
}) {
  const defaultValue = entriesPerPage.defaultValue || 100;
  const entries = entriesPerPage.entries?.map((el) => el.toString()) || ["5", "10", "15", "20"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  useEffect(() => setPageSize(defaultValue), [defaultValue]);

  const [currentPageGroup, setCurrentPageGroup] = useState(0);
  const pagesPerGroup = 10;
  const totalGroups = Math.ceil(pageOptions.length / pagesPerGroup);
  const groupStart = currentPageGroup * pagesPerGroup;
  const groupEnd = Math.min(groupStart + pagesPerGroup, pageOptions.length);

  useEffect(() => {
    const newGroup = Math.floor(pageIndex / pagesPerGroup);
    if (newGroup !== currentPageGroup) {
      setCurrentPageGroup(newGroup);
    }
  }, [pageIndex, currentPageGroup, pagesPerGroup]);

  const handleNextPage = () => {
    if (canNextPage) {
      if (pageIndex + 1 >= groupEnd) {
        setCurrentPageGroup((prev) => prev + 1);
      }
      nextPage();
    }
  };

  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  const handlePreviousPage = () => {
    if (canPreviousPage) {
      if (pageIndex < groupStart) {
        setCurrentPageGroup((prev) => prev - 1);
      }
      previousPage();
    }
  };

  const renderPagination = pageOptions.slice(groupStart, groupEnd).map((option) => (
    <MDPagination key={option} item active={pageIndex === option} onClick={() => gotoPage(option)}>
      {option + 1}
    </MDPagination>
  ));

  const entriesStart = pageIndex * pageSize + 1;
  const entriesEnd = Math.min(entriesStart + pageSize - 1, rows.length);

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {(entriesPerPage || canSearch) && (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={globalFilter || ""}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => setGlobalFilter(currentTarget.value)}
              />
            </MDBox>
          )}
        </MDBox>
      )}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </MDTypography>
          </MDBox>
        )}
        {pagination !== false && pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant || "gradient"}
            color={pagination.color || "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={handlePreviousPage}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination}
            {canNextPage && (
              <MDPagination item onClick={handleNextPage}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
            { entriesPerPage && (
              <MDBox display="flex" alignItems="center">
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={entries}
                  onChange={(event, newValue) => setPageSize(parseInt(newValue, 15))}
                  size="small"
                  sx={{
                    width: "5rem",
                    "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                      padding: "6px",
                      marginLeft: "9px",
                    },
                    "& .MuiTypography-root": {
                      marginLeft: "30px", // This is more general than targeting dynamically generated class names
                      color: "red",
                    },
                    "& .css-cz6ae8": {
                      marginLeft: "30px",
                    }
                  }}
                  renderInput={(params) => <MDInput {...params} />}
                />

                <MDTypography variant="caption" color="secondary">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entries per page
                </MDTypography>
              </MDBox>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 100, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
