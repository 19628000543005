import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
// import "react-toastify/dist/ReactToastify.css";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import axios from "axios";
import { useParams } from "react-router";
import DataTable from "examples/Tables/DataTable";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReservationModel from "components/ModelAdd/ReservationModel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, TextField, InputAdornment, Button, Pagination, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import { WidthFull } from "@mui/icons-material";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useGetReservationOnIdQuery } from "slice/apiReservationSlice";
const RoomReservationData = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [Reservation, setReservation] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [filteredReservation, setFilteredReservation] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [ReservationById, setReservationById] = useState([]);
  const { property_id } = useParams();
  const {data , error , isloading , refetch : refreshDataOnIdBasic} = useGetReservationOnIdQuery(property_id);

  const navigate = useNavigate();

  useEffect(()=>{
    setLoading(true)
      if(data?.data){
        setReservationById(data.data) 
        setLoading(false)
      }
  },[data])
  
  // const getReservationsOnId = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_PATH}/getroomReservationById/property_id/${property_id}`
  //     );
  //     if (response.data.success && Array.isArray(response.data.data)) {
  //       setReservationById(response.data.data);
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch reservation !");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   if (property_id) {
  //     refreshDataOnIdBasic();
  //   }
  // }, [property_id, modalVisible]);

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  // const addReservationData = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getroomReservation`, {});
  //     if (response.data.success && Array.isArray(response.data.data)) {
  //       setReservation(response.data.data);
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch properties!");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    if (!searchTerm.trim()) {
      // If the search term is empty, show all data
      setFilteredReservation(ReservationById);
    } else {
      // Perform the filtering when search term is not empty
      const filteredData = ReservationById.filter((reserve) => {
        const name = reserve.name?.toLowerCase().includes(searchTerm.toLowerCase());
        const email = reserve.email?.toLowerCase().includes(searchTerm.toLowerCase());
        const phone = reserve.phone?.toLowerCase().includes(searchTerm.toLowerCase());
        const arrived_date = reserve.arrived_date?.toLowerCase().includes(searchTerm.toLowerCase());
        const departure_date = reserve.departure_date
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());
        const notes = reserve.notes?.toLowerCase().includes(searchTerm.toLowerCase());
        const booking_source = reserve.booking_source
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());
        const deposite_amount = reserve.deposit_amount
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const payment_method = reserve.payment_method
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());

        return (
          name ||
          email ||
          phone ||
          arrived_date ||
          departure_date ||
          notes ||
          booking_source ||
          deposite_amount ||
          payment_method
        );
      });
      setFilteredReservation(filteredData);
    }
  }, [searchTerm, ReservationById]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handledelete = (property) => {
    console.log(property, "property123");
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Property record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_API_PATH}/delete-reservation-data/reservation_id/${property.reservation_id}`
          )
          .then((res) => {
            if (res.status === 200) {
              refreshDataOnIdBasic();
              toast.success(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("An error occurred while deleting the record.");
          });
      }
    });
  };

  // useEffect(() => {
  //   addReservationData();
  // }, [ReservationById]);
  const handleEdit = (property) => {
    setSelectedProperty(property);
    setModalVisible(true);
  };

  console.log(ReservationById, "ReservationByIdReservationById");

  const filteredRooms = filteredReservation.filter((property) => property && property.property_id);
  const columns = [
    {
      Header: () => (
        <MDTypography
          variant="h6"
          width={150}
          px={1}
          ml={2}
          style={{ fontSize: "16px", WidthFull, fontWeight: "medium", color: "#000" }}
        >
          Property Name
        </MDTypography>
      ),
      accessor: "propertyName",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          textAlign
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Name
        </MDTypography>
      ),
      accessor: "name",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Email
        </MDTypography>
      ),
      accessor: "email",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={4}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Phone
        </MDTypography>
      ),
      accessor: "phone",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Arrival date
        </MDTypography>
      ),
      accessor: "arrived_date",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          width={160}
          px={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Check in time
        </MDTypography>
      ),
      accessor: "check_in_time",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          width={120}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Departure date
        </MDTypography>
      ),
      accessor: "departure_date",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          width={170}
          px={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Check out time
        </MDTypography>
      ),
      accessor: "check_out_time",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={2.5}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Guest
        </MDTypography>
      ),
      accessor: "guest",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Notes
        </MDTypography>
      ),
      accessor: "notes",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={2}
          px={1}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Rent
        </MDTypography>
      ),
      accessor: "amount",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          width={170}
          px={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Booking source
        </MDTypography>
      ),
      accessor: "booking_source",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={2}
          width={150}
          px={1}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Deposite amount
        </MDTypography>
      ),
      accessor: "deposit_amount",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Payment method
        </MDTypography>
      ),
      accessor: "payment_method",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          width={150}
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Total stay
        </MDTypography>
      ),
      accessor: "total_stay",
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Actions
        </MDTypography>
      ),
      accessor: "actions",
    },
  ];

  const rows = filteredReservation?.map((property) => {
    const arrivedDate = new Date(property.arrived_date);
    const departureDate = new Date(property.departure_date);

    const parseTime = (time) => {
      if (!time) return { hours: "", minutes: "" };

      const timeParts = time.split(":");
      if (timeParts.length < 2) return { hours: "", minutes: "" };

      const hours = parseInt(timeParts[0], 10);
      const minutes = parseInt(timeParts[1], 10);

      return {
        hours: isNaN(hours) ? "" : hours,
        minutes: isNaN(minutes) ? "" : minutes,
      };
    };

    const checkIn = parseTime(property.check_in_time);
    const checkOut = parseTime(property.check_out_time);
    // let cleanerData = {};
    // try {
    //   cleanerData = property.cleaner ? JSON.parse(property.cleaner) : {};
    // } catch (error) {
    //   console.error("Failed to parse cleaner data:", property.cleaner, error);
    // }

    return {
      propertyName: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {`${property.property_name}`}
        </MDTypography>
      ),
      name: (
          <>
            {property.name.length > 10 ? (
              <Tooltip title={property.name} arrow>
                <MDTypography
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  style={{
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "150px", 
                    display: "inline-block",
                  }}
                >
                  {property.name}
                </MDTypography>
              </Tooltip>
            ) : (
              <MDTypography
                variant="caption"
                color="text"
                fontWeight="medium"
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px", 
                  display: "inline-block",
                }}
              >
                {property.name}
              </MDTypography>
            )}
          </>
           
      ),
      email: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {property.email || ""}
        </MDTypography>
      ),
      phone: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {property.phone || ""}
        </MDTypography>
      ),
      arrived_date: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {arrivedDate.toLocaleDateString() || ""}
        </MDTypography>
      ),
      check_in_time: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {checkIn.hours !== "" && checkIn.minutes !== ""
            ? `${checkIn.hours}:${checkIn.minutes < 10 ? "0" + checkIn.minutes : checkIn.minutes}`
            : ""}
        </MDTypography>
      ),
      departure_date: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {departureDate.toLocaleDateString() || ""}
        </MDTypography>
      ),
      check_out_time: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {checkOut.hours !== "" && checkOut.minutes !== ""
            ? `${checkOut.hours}:${
                checkOut.minutes < 10 ? "0" + checkOut.minutes : checkOut.minutes
              }`
            : ""}
        </MDTypography>
      ),
      guest: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {property.guest || ""}
        </MDTypography>
      ),
      notes: (
        <Tooltip title={property.notes || ""} arrow>
          <MDTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            style={{
              fontSize: "14px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px", // Adjust the width as needed
              display: "inline-block",
            }}
          >
            {property.notes || ""}
          </MDTypography>
        </Tooltip>
      ),

      amount: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {property.amount || property.rent_amount || ""}
        </MDTypography>
      ),
      booking_source: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {property.booking_source || ""}
        </MDTypography>
      ),

      deposit_amount: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {property.deposit_amount || ""}
        </MDTypography>
      ),
      payment_method: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {property.payment_method || ""}
        </MDTypography>
      ),
      total_stay: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {property.total_stay || ""}
        </MDTypography>
      ),
      actions: (
        <MDTypography
          variant="caption"
          color="text"
          ml={-3.5}
          display="flex"
          fontWeight="medium"
          className="action-fixed"
          style={{
            fontSize: "14px",
            ".action-fixed": {
              position: "fixed",
              right: "20px",
            },
          }}
        >
          <div>
            <Tooltip title="edit">
              <IconButton onClick={() => handleEdit(property)} sx={{ color: "#1e90ff" }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Delete">
              <IconButton sx={{ color: "red" }} onClick={() => handledelete(property)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </MDTypography>
      ),
    };
  });

  return (
    <>
      <DashboardLayout>
        <Sidenav color="primary" brandName="RentRoll" routes={routes} />
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  sx={{ backgroundColor: "#1e90ff" }}
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h5" color="white" style={{ float: "left" }}>
                    Reservations
                  </MDTypography>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center">
                  <Button
                      style={{
                        color: "black",
                        border: "1px solid white",
                        background: "white",
                        marginRight: "10px",
                      }}
                      onClick={handleBack}
                    >  <ArrowBackIcon style={{ marginRight: "8px" }} />Back
                    </Button>
                    <Button
                      style={{
                        color: "black",
                        border: "1px solid white",
                        background: "white",
                        marginRight: "10px",
                      }}
                      onClick={() => setModalVisible(true)}
                    >
                      Add Reservation
                    </Button>
                    <TextField
                      placeholder="Search "
                      size="small"
                      sx={{
                        "& .MuiInputBase-root": {
                          backgroundColor: "#ffffff",
                          color: "#000000",
                          border: "none",
                          padding: "3px 10px",
                        },
                        "& .MuiInputLabel-root": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-input": {
                          padding: "8px",
                        },
                      }}
                      inputProps={{ "aria-label": "Search" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleSearch}
                      value={searchTerm}
                    />
                    
                  </MDBox>
                </MDBox>
                {loading ? (
                  <MDTypography
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    align="center"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
                  </MDTypography>
                ) : (
                  <MDBox pt={3}>
                    {filteredRooms.length > 0 ? (
                      <>
                        <DataTable
                          table={{ columns, rows }}
                          isSorted={false}
                          entriesPerPage={{ defaultValue: 10, entries: [10, 20, 30, 40, 50, 60] }}
                          showTotalEntries={false}
                          noEndBorder
                        />
                      </>
                    ) : (
                      <MDTypography variant="h6" align="center" m={2} sx={{ color: "red" }}>
                        No Reservation found.
                      </MDTypography>
                    )}
                  </MDBox>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <ReservationModel
          isVisible={modalVisible}
          addReservationData={refreshDataOnIdBasic}
          Close={() => {
            setModalVisible(false);
            setSelectedProperty(null);
          }}
          selectedProperty={selectedProperty}
        />
      </DashboardLayout>
    </>
  );
};

export default RoomReservationData;
