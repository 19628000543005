import React, { useEffect, useState } from 'react';
import { useGetAllPropertiesQuery } from 'slice/apipropertyslice';

const Tester = () => {
    const { data, error, isLoading } = useGetAllPropertiesQuery();
    console.log('-------->',data)
    const [apiData, setApiData] = useState([]);

    useEffect(() => {
        if (data.data) {
            setApiData(data.data);
        }
    }, [data]); 
    

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error occurred: {error.message}</div>;

    return (
        <div>
            {console.log(apiData , "apidata")}
           
            { apiData && apiData.map((property) => (
                <div key={property.property_id}>
                    <div>Property Name: {property.property_name}</div>
                    <div>Zip: {property.zip}</div>
                    
                </div>
            ) )}
        </div>
    );
};

export default Tester;
