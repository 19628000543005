import { fetchBaseQuery , createApi } from "@reduxjs/toolkit/query/react";

export const apiReservationSlice = createApi({
    reducerPath : 'reservationData',
    baseQuery : fetchBaseQuery({
        baseUrl : process.env.REACT_APP_API_PATH,
    }),
    endpoints : (builder)=>({
        getReservationOnId : builder.query({
            query : (property_id)=>`/getroomReservationById/property_id/${property_id} `
        })
    })
})
console.log("apiReservationSlice", apiReservationSlice)
export const {useGetReservationOnIdQuery} = apiReservationSlice;

