import React from "react";
import ReactDOM from "react-dom/client"; // Note the change to 'react-dom/client'
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "App";
// import store from "store/store";
import { store } from "store/store";
// Get the root element from the DOM
const rootElement = document.getElementById("root");

// Create the root using createRoot
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
