import { fetchBaseQuery , createApi } from "@reduxjs/toolkit/query/react";

export const apiCleaningSlice = createApi({
    reducerPath : 'cleaningGetApi',
    baseQuery : fetchBaseQuery({
        baseUrl : process.env.REACT_APP_API_PATH,
    }),
    endpoints : (builder) =>({
        getCleaningById : builder.query({
            query : (property_id)=> `/getcleaningdata/property_id/${property_id}`
        })
    }) 
})
console.log (  "apicleaningnslice" ,apiCleaningSlice )
export const {useGetCleaningByIdQuery} = apiCleaningSlice