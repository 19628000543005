import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query'
import { apiCleaningSlice } from "slice/apiCleaningSlice";
import { apipropertyslice } from "slice/apipropertyslice";
import { apiReservationSlice } from "slice/apiReservationSlice";
export const store = configureStore({
  reducer: {
    [apipropertyslice.reducerPath]: apipropertyslice.reducer,
    [apiReservationSlice.reducerPath] : apiReservationSlice.reducer,
    [apiCleaningSlice.reducerPath] : apiCleaningSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware()
        .concat(apipropertyslice.middleware)
        .concat(apiReservationSlice.middleware)
        .concat(apiCleaningSlice.middleware)
        
  
},); 

setupListeners(store.dispatch);


