import React, { useState, useEffect } from "react";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import Nav from "examples/Navbars/Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button} from "@mui/material";
import { useNavigate } from "react-router-dom";

const PublicCleaning = () => {
  const [cleaningData, setCleaningData] = useState([]);
  const [loading, setLoading] = useState(true);
  const Token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  }

  const fetchCleaningData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getcleaningdata`);
      if (response.data.success && Array.isArray(response.data.data)) {
        setCleaningData(response.data.data);
      } else {
        console.error("Failed to fetch cleaning data.");
      }
    } catch (error) {
      console.error("An error occurred while fetching cleaning data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCleaningData();
  }, []);



  // Define cellStyle for reuse
  const rowStyle = {
    borderBottom: "1px solid #ddd", // Border only at the bottom of each row
  };

  const cellStyle = {
    padding: "10px 8px",
    paddingLeft: "10px",
    textAlign: "left",
    fontSize: "14px",
  };

  return (
    <>
      <Nav />
      <div style={{backgroundColor : "white"}}>

      <div
        style={{
          overflowX: "auto",
          position: "relative",
          background : "white",
          maxHeight: "calc(100vh - 80px)",
        }}
      >
        <table
          style={{
            marginTop: "20px",
            width: "100%",
            borderCollapse: "collapse", // Ensure rows look connected
            backgroundColor: "white",
          }}
        >
          <thead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#fff",
              zIndex: 1,
              padding: "10px",
            }}
          >
            <tr style={{ fontSize: "20px", marginBottom: "10px" }}>
              <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize : "16px" }}>
                Cleaning Date
              </th>
              <th style={{ ...cellStyle, minWidth: "100px", whiteSpace: "nowrap", fontSize : "16px"}}>Properties</th>
              <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize : "16px" }}>Name</th>
              <th style={{ ...cellStyle, minWidth: "150px", whiteSpace: "nowrap", fontSize : "16px" }}>Email</th>
              <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize : "16px" }}>
                Move Out Date
              </th>
              <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize : "16px" }}>
                Check In Time
              </th>
              <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize : "16px" }}>
                Check Out Time
              </th>
              <th style={{ ...cellStyle, minWidth: "100px", whiteSpace: "nowrap", fontSize : "16px" }}>Guests</th>
              <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize : "16px" }}>Cleaner</th>
              <th style={{ ...cellStyle, minWidth: "150px", whiteSpace: "nowrap", fontSize : "16px"}}>
                Cleaner Email
              </th>
              <th style={{ ...cellStyle, minWidth: "100px", whiteSpace: "nowrap", fontSize : "16px"}}>Todo</th>
              <th style={{ ...cellStyle, minWidth: "150px", whiteSpace: "nowrap", fontSize : "16px"}}>Note</th>
              <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize : "16px"}}>Origin</th>
              <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize : "16px"}}>Maintenance</th>
            </tr>
          </thead>

          <tbody style={{ fontSize: "20px", color: "GrayText", fontWeight: "bold", overflowY: "auto" }}>
            {cleaningData.length === 0 ? (
              <tr>
                <td
                  colSpan="14"
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    No data found
                  </span>
                </td>
              </tr>
            ) : (
              cleaningData.map((row, index) => (
                <tr key={index} style={rowStyle}>
                  <td style={cellStyle}>
                    <div style={{ marginTop: "10px" }}>
                      {new Date(row.cleaning_date).toLocaleDateString("en-GB")}
                    </div>
                  </td>
                  <td style={cellStyle}>{row.property_name}</td>
                  <td style={cellStyle}>
                    {row.reservation_name.length > 15 ? (
                      <Tooltip title={row.reservation_name}>
                        <span style={{ cursor: "pointer" }}>
                          {row.reservation_name.substring(0, 15)}...
                        </span>
                      </Tooltip>
                    ) : (
                      row.reservation_name
                    )}
                  </td>
                  <td style={cellStyle}>{row.reservation_email}</td>
                  <td style={cellStyle}>
                    {new Date(row.departure_date).toLocaleDateString("en-GB")}
                  </td>
                  <td style={cellStyle}>{row.check_in_time}</td>
                  <td style={cellStyle}>{row.check_out_time}</td>
                  <td style={cellStyle}>{row.guests}</td>
                  <td style={cellStyle}>{row.cleaner_name}</td>
                  <td style={cellStyle}>{row.cleaner_email}</td>
                  <td style={cellStyle}>{row.todo}</td>
                  <td style={cellStyle}>
                    {row.notes && row.notes.length > 10 ? (
                      <Tooltip title={row.notes}>
                        <span style={{ cursor: "pointer" }}>{row.notes.substring(0, 10)}...</span>
                      </Tooltip>
                    ) : (
                      row.notes || ""
                    )}
                  </td>
                  <td style={cellStyle}>{row.booking_source}</td>
                  <td style={cellStyle}>{row.maintenance}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      </div>
    </>
  );
};

export default PublicCleaning;
