import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { IconButton, TextField, InputAdornment, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import ModelData from "components/ModelAdd/ModelData";
import CircularProgress from "@mui/material/CircularProgress";
import MDButton from "components/MDButton";
import ReservationModel from "components/ModelAdd/ReservationModel";
import CleaningModel from "components/Cleaning/CleaningModel";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useGetAllPropertiesQuery } from "slice/apipropertyslice";
import { CloseFullscreen } from "@mui/icons-material";

const ListProperties = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [Isreservation, setIsreservation] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [IsExpensesModel, setIsExpensesModel] = useState(false);
  const [cleaningData, setCleaningData] = useState(null);
  const [dropDownProperty, setDropDownProperty] = useState("");
  const [tester , setTester] = useState("")
   const { data, error, isLoading , refetch : refreshgetproperty} = useGetAllPropertiesQuery();
  
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true)
        if (data?.data) {
          setProperties(data.data);
        }
        setLoading(false) 
    }, [data]);
  console.log(tester , "testertestertester123")
  
  const handleresesrvation = async (property_id) => {
    navigate(`/room-reservation/${property_id}`);
  };
  
  const handleCleaningService = async (property_id) => {
    navigate(`/cleaning/${property_id}`);
  };
  
  const handleExpense = async (property_id) => {
    navigate(`/expense/${property_id}`);
  };
  
  // const addProperty = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getproperties`);
  //     if (response.data.success && Array.isArray(response.data.data)) {
  //       setProperties(response.data.data);
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch properties!");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   addProperty();
  // }, []);
  
  useEffect(() => {
    const filteredData = properties.filter((property) =>
      property.property_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
    setFilteredProperties(filteredData);
  }, [searchTerm, properties]);
  
  useEffect(() => {
    const options = properties.map((property) => property.property_name); // Extract property names
    setDropDownOptions(options); // Set dropdown options
  }, [properties]);

  useEffect(() => {
    let filteredData = properties;

    // Apply dropdown filter if a property is selected
    if (dropDownProperty) {
      filteredData = filteredData.filter((property) => {
        const propertyname = property.property_name
          .toLowerCase()
          .includes(dropDownProperty.toLowerCase());
        const ward = property.ward?.toLowerCase().includes(dropDownProperty.toLowerCase());
        const city = property.city?.toLowerCase().includes(dropDownProperty.toLowerCase());
        const zipcode = property.zip?.toString().includes(dropDownProperty.toLowerCase());
        const street = property.street?.toLowerCase().includes(dropDownProperty.toLowerCase());

        return propertyname || ward || city || zipcode || street;
      });
    }

    setFilteredProperties(filteredData); // Update the filtered properties
  }, [dropDownProperty, properties]);

  console.log(dropDownProperty, "dropDownProperty");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleEdit = (property) => {
    setSelectedProperty(property);
    setModalVisible(true);
  };

  const handleDelete = (property) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Property record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API_PATH}/deleteproperties/${property.property_id}`)
          .then((res) => {
            if (res.data.status === 200) {
              refreshgetproperty() 
              toast.success(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("An error occurred while deleting the record.");
          });
      }
    });
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedProperty(null);
  };
  const handleReservation = (roomId) => {
    setIsreservation(true);
    setSelectedRoomId(roomId);
  };
  const handleExpensess = (roomId) => {
    setIsExpensesModel(true);
    setCleaningData(roomId);
  };
  const handleClose = () => {
    setIsreservation(false);
    setSelectedRoomId(null);
  };
  const shouldShowModal = location.pathname !== "/properties/list-properties";
  const columns = [
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Properties
        </MDTypography>
      ),
      accessor: "propertyname",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={2}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Address
        </MDTypography>
      ),
      accessor: "city",
    },
    // {
    //   Header: () => (
    //     <MDTypography
    //       variant="h6"
    //       ml={3}
    //       style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
    //     >
    //       Zip code
    //     </MDTypography>
    //   ),
    //   accessor: "zip",
    // },
    // {
    //   Header: () => (
    //     <MDTypography
    //       variant="h6"
    //       ml={3}
    //       style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
    //     >
    //       Ward
    //     </MDTypography>
    //   ),
    //   accessor: "ward",
    // },
    // {
    //   Header: () => (
    //     <MDTypography
    //       variant="h6"
    //       ml={3}
    //       style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
    //     >
    //       Street
    //     </MDTypography>
    //   ),
    //   accessor: "street",
    // },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          width={170}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Property Access Guide
        </MDTypography>
      ),
      accessor: "files",
    },
    // {
    //   Header: () => (
    //     <MDTypography
    //       variant="h6"
    //       ml={3}
    //       style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
    //     >
    //       Add cleaning
    //     </MDTypography>
    //   ),
    //   accessor: "cleaning",
    // },
    // {
    //   Header: () => (
    //     <MDTypography
    //       variant="h6"
    //       ml={3}
    //       style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
    //     >
    //       Reservation
    //     </MDTypography>
    //   ),
    //   accessor: "reservation",
    // },
    ...(shouldShowModal
      ? [
          {
            Header: () => (
              <MDTypography
                variant="h6"
                align="center"
                ml={3}
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#000",
                  alignItems: "center",
                }}
              >
                Actions
              </MDTypography>
            ),
            accessor: "actions",
          },
        ]
      : []),
  ];
  const rows = filteredProperties
    .map((property) => ({
      propertyname: (
        <MDTypography
          variant="caption"
          color="text"
          sx={{paddingTop: 0,
            paddingBottom: 0}}
          fontWeight="medium"
          style={{ fontSize: "14px" , }}
        >
          {property.property_name || ""}
        </MDTypography>
      ),
      city: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {`${property.zip || ""}${property.zip ? ", " : ""}${property.city || ""}${
            property.city ? ", " : ""
          }${property.ward || ""}${property.ward ? ", " : ""}${property.street || ""}` || ""}
        </MDTypography>
      ),
      // zip: (
      //   <MDTypography
      //     variant="caption"
      //     color="text"
      //     fontWeight="medium"
      //     style={{ fontSize: "14px" }}
      //   >
      //     {property.zip || ""}
      //   </MDTypography>
      // ),
      // ward: (
      //   <MDTypography
      //     variant="caption"
      //     color="text"
      //     fontWeight="medium"
      //     style={{ fontSize: "14px" }}
      //   >
      //     {property.ward || ""}
      //   </MDTypography>
      // ),
      // street: (
      //   <MDTypography
      //     variant="caption"
      //     color="text"
      //     fontWeight="medium"
      //     style={{ fontSize: "14px" }}
      //   >
      //     {property.street || ""}
      //   </MDTypography>
      // ),

      files: property.pdf_file ? (
        <a href={property.pdf_file || ""} target="_blank" rel="noopener noreferrer">
          <MDTypography
          variant="caption"
          color="blue"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
          >
          Show file
          </MDTypography>
        </a>
      ) : (
        ""
      ),
      // cleaning: (

      // ),
      // reservation: (

      // ),

      actions: (
        <MDTypography
          variant="caption"
          color="text"
          display="flex"
          fontWeight="medium"
          className="action-fixed"
          gap={1}
          style={{ fontSize: "14px", right: "20px" }}
        >
          <div>
          <MDTypography
          variant="caption"
          color="text"
          align="center"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          <div>
            <MDTypography
            ml={6}
            mr={1}
            mt={1}
            color="text"
          fontWeight="medium"
              style={{
                color: "#1e90ff" , cursor : "pointer" ,   fontSize: "14px" 
               
              }}
              onClick={() => handleReservation(property)}
            >
              Add reservation
            </MDTypography>
          </div>
        </MDTypography>
          </div>
          {/* <MDTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            style={{
              fontSize: "8px",
            }}
          >
            <div
              style={{
                margin: 0, // Remove default margin
                padding: 0, // Remove default padding
                // Ensure the button stays tightly enclosed
              }}
            >
              <MDButton
                style={{
                  color: "white",
                  border: "1px solid white",
                  background: "#1e90ff",
                  // padding: "2px 8px", // Adjust padding for tighter button
                }}
                onClick={() => handleReservation(property)}
              >
                Add reservation
              </MDButton>
            </div>
          </MDTypography> */}

          <div>
          <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          mr={11}
         
          style={{ fontSize: "14px" }}
        >
          <div>
            <MDTypography
            mt={1}
            color="text"
          fontWeight="medium"
              style={{
                 color: "#1e90ff" , cursor : "pointer" ,  fontSize: "14px"

              }}
              onClick={() => handleExpensess(property)}
            >
              Add cleaning
            </MDTypography>
          </div>
        </MDTypography>
          </div>
          {/* <MDTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            padding="0px 20px"
            style={{ fontSize: "14px" }}
          >
            <div>
              <MDButton
                style={{
                  color: "white",
                  border: "1px solid white",
                  background: "#1e90ff",
                  // padding: "2px 8px",
                }}
                onClick={() => handleExpensess(property)}
              >
                Add cleaning
              </MDButton>
            </div>
          </MDTypography> */}
          <div>
            <Tooltip title=" Show reservations">
              <MDTypography
              ml={0.8}
              mt={1}
              color="text"
          fontWeight="medium"
                sx={{ color: "#1e90ff" , cursor : "pointer" , fontSize: "14px" }}
                onClick={() => handleresesrvation(property.property_id)}
              >
                Reservations 
              </MDTypography>
            </Tooltip>
          </div>
          {/* <MDTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            style={{ fontSize: "8px" }}
          >
            <div>
              <Tooltip title="show reservation">
                <MDButton
                  style={{
                    color: "white",
                    border: "1px solid white",
                    background: "#1e90ff",
                    // padding: "2px 8px",
                  }}
                  padding={1}
                  onClick={() => handleresesrvation(property.property_id)}
                >
                  Reservations
                </MDButton>
              </Tooltip>
            </div>
          </MDTypography> */}
          <div>
            <Tooltip title="Show cleanings">
              <MDTypography
              ml={1.3}
              mt={1}
              color="text"
          fontWeight="medium"
                sx={{ color: "#1e90ff" , cursor : "pointer" , fontSize: "14px"  }}
                onClick={() => handleCleaningService(property.property_id)}
              >
                Cleanings
              </MDTypography>
            </Tooltip>
          </div>
          {/* <MDTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            padding="0px 20px"
            style={{ fontSize: "8px" }}
          >
            <div>
              <Tooltip title="show cleaning">
                <MDButton
                  style={{
                    color: "white",
                    border: "1px solid white",
                    background: "#1e90ff",
                    // padding: "2px 8px",
                  }}
                  padding={1}
                  onClick={() => handleCleaningService(property.property_id)}
                >
                  Cleanings
                </MDButton>
              </Tooltip>
            </div>
          </MDTypography> */}
          <div>
            <Tooltip title="Show expenses">
              <MDTypography
              ml={1.3}
              mt={1}
              color="text"
          fontWeight="medium"
                sx={{ color: "#1e90ff" , cursor : "pointer" , fontSize: "14px" }}
                onClick={() => handleExpense(property.property_id)}
              >
                Expenses
              </MDTypography>
            </Tooltip>
          </div>
          {/* <MDTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            padding="0px 0px"
            style={{ fontSize: "8px" }}
          >
            <div>
              <Tooltip title="Show expenses">
                <MDButton
                  style={{
                    color: "white",
                    border: "1px solid white",
                    background: "#1e90ff",
                    // padding: "2px 8px",
                  }}
                  padding={1}
                  onClick={() => handleExpense(property.property_id)}
                >
                  Expenses
                </MDButton>
              </Tooltip>
            </div>
          </MDTypography> */}

          <div>
            <Tooltip title="Edit">
              <MDTypography
                ml={2}
                onClick={() => handleEdit(property)}
                sx={{ color: "#1e90ff", cursor: "pointer", fontSize: "20px" }}
              >
                <EditIcon />
              </MDTypography>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Delete">
              <MDTypography
                ml={1.5}
                sx={{ color: "red", padding: "2px", cursor: "pointer", fontSize: "20px" }}
                onClick={() => handleDelete(property)}
              >
                <DeleteIcon />
              </MDTypography>
            </Tooltip>
          </div>
        </MDTypography>
      ),
    }));

  return (
    <>
      <DashboardLayout>
        <Sidenav color="primary" brandName="RentRoll" routes={routes} />
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  sx={{ backgroundColor: "#1e90ff" }}
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h5" color="white">
                    Properties Table
                  </MDTypography>
                  <MDBox display="flex" justifyContent="flex-end" alignItems="center">
                    <MDBox mb={2} mr={2} mt={1.7} width={220}>
                      <Autocomplete
                        options={dropDownOptions} // Use dropdown options
                        getOptionLabel={(option) => option} // Options are strings (property names)
                        onChange={(event, value) => setDropDownProperty(value || "")} // Update selected property
                        value={dropDownProperty}
                        style={{ border: "none" }}
                        filterOptions={(options, state) =>
                          options.filter((option) =>
                            option.toLowerCase().includes(state.inputValue.toLowerCase())
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search Properties"
                            style={{ border: "none" }}
                            sx={{
                              background: "white",
                              color: "black",
                              border: "none",
                              borderRadius : "8px ",
                              margin: "0px",
                              ".MuiOutlinedInput-root": {
                                padding: "2px", // Set padding to 2px
                              },
                              ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none", // Remove the border on focus
                                },
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none", // Remove the border for normal state
                              },
                            }}
                          />
                        )}
                      />
                    </MDBox>

                    {shouldShowModal && (
                      <MDButton
                        style={{
                          color: "black",
                          border: "1px solid white",
                          background: "#white",
                          marginRight: "16px",
                          padding: "3px 10px",
                        }}
                        onClick={() => setModalVisible(true)}
                      >
                        Add Property
                      </MDButton>
                    )}

                    <TextField
                      placeholder="Search "
                      size="small"
                      sx={{
                        "& .MuiInputBase-root": {
                          backgroundColor: "#ffffff",
                          color: "#000000",
                          border: "none",
                          padding: "3px 10px",
                        },
                        "& .MuiInputLabel-root": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-input": {
                          padding: "8px",
                        },
                      }}
                      inputProps={{ "aria-label": "Search" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleSearch}
                      value={searchTerm}
                    />
                  </MDBox>
                </MDBox>
                {loading ? (
                  <MDTypography
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    align="center"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
                  </MDTypography>
                ) : (
                  <MDBox>
                    {filteredProperties.length > 0 ? (
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={false}
                        showTotalEntries={false}
                        entriesPerPage={{ defaultValue: 10, entries: [10, 20, 30, 40, 50, 60] }}
                        noEndBorder
                      />
                    ) : (
                      <MDTypography variant="h6" align="center" m={2} sx={{ color: "red" }}>
                        No properties found.
                      </MDTypography>
                    )}
                  </MDBox>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {shouldShowModal && (
          <ModelData
            isVisible={modalVisible}
             addProperty={refreshgetproperty}
            close={closeModal}
            selectedProperty={selectedProperty}
          />
        )}
        <ReservationModel isVisible={Isreservation} Close={handleClose} roomId={selectedRoomId} />
        <CleaningModel
          isVisible={IsExpensesModel}
          Close={() => {
            setIsExpensesModel(false);
          }}
          roomId={cleaningData}
        />
      </DashboardLayout>
    </>
  );
};

export default ListProperties;
