import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const apipropertyslice = createApi ({
  reducerPath : 'PropertyApi',
  baseQuery : fetchBaseQuery({
    baseUrl : process.env.REACT_APP_API_PATH
  }),
  endpoints : (builder)=>({
    GetAllProperties : builder.query({
      query :()=> `/getproperties`
    })
  })
},)

console.log("propertyslice", apipropertyslice)

 export const {useGetAllPropertiesQuery} = apipropertyslice;


